import {Navigate, Outlet} from "react-router-dom";
import {useProducts} from "./products";

export default function UrlRedirect() {
  const {companyId, tableId}: any = useProducts();

  if (!companyId || !tableId) {
    return <Navigate to="not-found" replace />;
  }

  return <Outlet />;
}
