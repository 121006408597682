import React, {useEffect, useState} from "react";
import * as S from "./styles";
import {Avatar} from "@mui/material";
import {companyDoc} from "../../repositories/companyRepositories";
import {getDoc} from "firebase/firestore";

export function CompanyData() {
  const [companyData, setCompanyData] = useState<any>();

  useEffect(() => {
    (async () => {
      const companyData: any = await getDoc(companyDoc());
      setCompanyData(companyData.data());
    })();
  }, []);

  return (
    <S.CompanyDataContainer>
      <S.CompanyName>{companyData?.name}</S.CompanyName>
      <Avatar
        alt={companyData?.name}
        sx={{
          width: 100,
          height: 100,
          transition: "0.6",
          left: 24,
          filter: "drop-shadow(0px 1px 4px #0007)",
        }}
        src="https://sd-media.simplydelivery.io/60797a8436fbe/webshop/images/webshopTheme_brand.png?v=1619693286"
      />
    </S.CompanyDataContainer>
  );
}
