import React from "react";
import {Header} from "../../components/Header";
import {CompanyData} from "../../components/CompanyData";
import {CategoriesList} from "../../components/CategoriesList";
import {ProductsList} from "../../components/ProductsList";
import {BottomTab} from "../../components/BottomTab";

export function Home() {
  return (
    <React.Fragment>
      <Header />
      <CompanyData />
      <CategoriesList />
      <ProductsList />
      <BottomTab />
    </React.Fragment>
  );
}
