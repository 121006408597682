import {doc} from "firebase/firestore";
import {db} from "../firebase";

function getCompanyId() {
  const [companyId] = window.location.pathname
    .split("/")
    .filter((i) => i.length > 0);
  return companyId;
}

export const companyDoc = () => doc(db, "companies", getCompanyId());
