import styled from "styled-components";

export const DrawerContainer = styled.div`
  width: 100%;
  padding: 12px;
  display: flex;
  flex-direction: column;
`;

export const ProductContainer = styled.div`
  display: grid;
  box-shadow: 0 2px 4px #0003;
  border-radius: 6px;
  grid-template-columns: 1fr 100px;
  grid-template-rows: 20px 1fr;
  row-gap: 8px;
  column-gap: 8px;
  padding: 12px;
`;

export const ProductTitle = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
`;

export const ProductDescription = styled.p`
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;
  color: #757575;
  align-self: self-end;
`;

export const ProductPrice = styled.p`
  font-size: 20px;
  font-weight: 600;
  color: #3a3;
  justify-self: end;
  line-height: 20px;
`;

export const ProductQtdText = styled.p`
  font-size: 15px;
  font-weight: 500;
  color: #757575;
  line-height: 15px;
  align-self: end;
`;

export const TitleSection = styled.p`
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 600;
  color: #000;
  line-height: 20px;
`;

export const DataText = styled.p`
  margin-bottom: 12px;
  /* margin-top: 12px; */
  font-size: 13px;
  font-weight: 400;
  color: #757575;
  line-height: 13px;
`;

export const TotalToPayInOrderCOntainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TotalToPayInOrderText = styled.p`
  font-size: 17px;
  font-weight: 500;
  color: #757575;
  line-height: 17px;
`;

export const TotalToPayTitle = styled.p`
  font-size: 20px;
  font-weight: 600;
  color: #000;
  line-height: 20px;

  span {
    color: #3a3;
  }
`;

export const ProductsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  gap: 12px;
`;
