import styled from "styled-components";

export const CompanyDataContainer = styled.div<any>`
  position: relative;
  top: 62px;
  box-shadow: 0px 1px 4px #0007;
  width: 100%;
  height: 138px;
  display: flex;
  background-color: #4ae;
  transition: 0.6s;
  overflow: hidden;
  background-image: linear-gradient(to top, #000b, #0000),
    url(https://images6.alphacoders.com/951/951313.jpg);
  background-size: cover;
  background-position: cover;
  align-items: center;
  margin-bottom: 62px;
`;

export const CompanyName = styled.p`
  position: absolute;
  bottom: 12px;
  right: 12px;
  font-size: 28px;
  line-height: 28px;
  color: #fff;
  font-weight: 700;
`;
