import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
`;

export const PayedText = styled.p`
  font-size: 32px;
  font-weight: 600;
  color: #6e6;
`;
