import styled from "styled-components";

export const HeaderContainer = styled.header`
  width: 100%;
  height: 62px;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background-color: #4ae;
  position: fixed;
  z-index: 1;
`;

export const Right = styled.div`
  height: 100%;
  display: flex;
  gap: 12px;
  align-items: center;
`;
