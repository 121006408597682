import Button from "@mui/material/Button";
import * as S from "./styles";
import {collection, doc, getDoc, updateDoc} from "firebase/firestore";
import {db} from "../../firebase";
import {useNavigate} from "react-router-dom";
import {useProducts} from "../../contexts/products";
import {companyDoc} from "../../repositories/companyRepositories";

export default function Payed() {
  const navigate = useNavigate();
  const {companyId, tableId}: any = useProducts();

  async function pay() {
    const ordId = localStorage.getItem("orderId");
    if (ordId) {
      const orderRef = doc(companyDoc(), "orders", ordId);
      await updateDoc(orderRef, {status: "payed"});
      localStorage.removeItem("orderId");
      navigate(`/${companyId}/${tableId}`);
    }
  }

  return (
    <S.Container>
      <S.PayedText>Payed</S.PayedText>
      <Button onClick={pay}>OK</Button>
    </S.Container>
  );
}
