import React, {
  Dispatch,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {IProduct} from "../models/product";
import {IOrder} from "../models/order";
import {db} from "../firebase";
import {doc, getDoc} from "firebase/firestore";
import {companyDoc} from "../repositories/companyRepositories";

export interface ProductsTypes {
  products: IProduct[];
  setProducts: Dispatch<any>;
  productsSelected: any[];
  addProduct: (p: any) => void;
  removeProduct: (p: any) => void;
  totalValueProductsSelected: number;
  clearProductsSelectedAndQtd: () => void;
  selectedProducts: IProduct[];
  setSelectedProducts: Dispatch<React.SetStateAction<IProduct[]>>;
  companyId: string;
  tableId: string;
}

const ProductsContext = createContext<ProductsTypes | undefined>(undefined);

export function ProductsProvider({children}: any) {
  const [products, setProducts] = useState<ProductsTypes["products"]>([]);
  const [selectedProducts, setSelectedProducts] = useState<
    ProductsTypes["products"]
  >([]);
  const productsSelected = selectedProducts.filter((prod: any) => prod.qtd > 0);
  const totalValueProductsSelected = productsSelected.reduce(
    (acm, v) => acm + v.price * (v.qtd ? v.qtd : 1),
    0
  );

  const [companyId, tableId] = window.location.pathname
    .split("/")
    .filter((i) => i.length > 0);

  function addProduct(p: IProduct) {
    setProducts(
      products.map((prod: any) => {
        const equal = prod.id === p.id;
        return equal ? {...prod, qtd: prod.qtd + 1} : prod;
      })
    );
    const productsAlredySelected = selectedProducts.some((i) => i.id === p.id);
    setSelectedProducts(
      productsAlredySelected
        ? selectedProducts.map((prod) =>
            prod.id === p.id ? {...prod, qtd: (prod.qtd || 0) + 1} : prod
          )
        : [...selectedProducts, {...p, qtd: 1}]
    );
  }

  function removeProduct(p: any) {
    setProducts(
      products.map((prod: any) =>
        prod.id === p.id
          ? {...prod, qtd: prod.qtd > 0 ? prod.qtd - 1 : prod.qtd}
          : prod
      )
    );
    const productsAlredySelected = selectedProducts.some((i) => i.id === p.id);
    setSelectedProducts(
      productsAlredySelected
        ? selectedProducts
            .map((prod) =>
              prod.id === p.id ? {...prod, qtd: (prod.qtd || 1) - 1} : prod
            )
            .filter((prod: any) => prod.qtd > 0)
        : selectedProducts
    );
  }

  function clearProductsSelectedAndQtd() {
    setProducts(products.map((p) => ({...p, qtd: 0})));
    setSelectedProducts([]);
  }

  useEffect(() => {
    (async () => {
      const ordId = localStorage.getItem("orderId");
      if (ordId) {
        const orderRef = await doc(companyDoc(), "orders", ordId);
        const orderItem = await getDoc(orderRef);
        const orderData = orderItem.data();
        if (orderData?.status === "payed") {
          localStorage.removeItem("orderId");
        }
      }
    })();
  }, []);

  return (
    <ProductsContext.Provider
      value={{
        products,
        setProducts,
        productsSelected,
        addProduct,
        removeProduct,
        totalValueProductsSelected,
        clearProductsSelectedAndQtd,
        selectedProducts,
        setSelectedProducts,
        tableId,
        companyId,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
}

export function useProducts() {
  return useContext(ProductsContext);
}
