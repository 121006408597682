import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {Home} from "./pages/Home";
import {t} from "i18next";
import UrlRedirect from "./contexts/UrlRedirect";
import Payed from "./pages/Payed";

export default function Routes() {
  const NotFound = () => (
    <div>
      <h1>{t("pageNotFound")}</h1>
    </div>
  );

  const router = createBrowserRouter([
    {
      path: "not-found",
      element: <NotFound />,
    },
    {
      path: "",
      element: <UrlRedirect />,
      children: [
        {
          path: "/:companyId/:tableId",
          element: <Home />,
        },
        {
          path: "/:companyId/:tableId/payed",
          element: <Payed />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}
