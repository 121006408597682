import React, {useEffect, useState} from "react";
import * as S from "./styles";
import {Button, Divider, IconButton, SwipeableDrawer} from "@mui/material";
import {useProducts} from "../../contexts/products";
import PaymentsIcon from "@mui/icons-material/Payments";
import {collection, doc, getDoc} from "firebase/firestore";
import {db} from "../../firebase";
import {IOrder} from "../../models/order";
import CloseIcon from "@mui/icons-material/Close";
import {useSnackbar} from "notistack";
import {t} from "i18next";
import {useNavigate} from "react-router-dom";
import {companyDoc} from "../../repositories/companyRepositories";

export function DrawerPay({open, onClose, onOpen}: any) {
  const {
    totalValueProductsSelected,
    clearProductsSelectedAndQtd,
    companyId,
    tableId,
  }: any = useProducts();
  const navigate = useNavigate();
  const [ordersList, setOrdersList] = useState<IOrder["products"]>([]);
  const {enqueueSnackbar} = useSnackbar();

  const ordersValue = ordersList.reduce(
    (acm, v) => acm + v.price * (v.qtd || 1),
    0
  );

  async function pay() {
    localStorage.removeItem("orders");
    setOrdersList([]);
    onClose();
    clearProductsSelectedAndQtd();
    navigate(`/${companyId}/${tableId}/payed`);
  }

  useEffect(() => {
    (async () => {
      if (open) {
        const ordId = localStorage.getItem("orderId");
        if (ordId) {
          const orderRef = await doc(companyDoc(), "orders", ordId);
          await getDoc(orderRef).then((docSnap) => {
            if (docSnap.exists()) {
              const data = docSnap.data();
              let resultado: any = [];

              data.products.forEach((obj: any) => {
                let prod = resultado.find((i: any) => i.id === obj.id);
                if (prod) {
                  prod.qtd += obj.qtd;
                } else {
                  resultado.push({...obj, obj: obj.qtd});
                }
              });

              setOrdersList(resultado);
            }
          });
        }
      }
    })();
  }, [open]);

  return (
    <SwipeableDrawer
      swipeAreaWidth={0}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        position: "absolute",
        top: 12,
      }}
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
    >
      <S.DrawerContainer>
        <IconButton
          onClick={onClose}
          sx={{
            width: 30,
            height: 30,
            position: "absolute",
            right: 6,
            top: 6,
          }}
        >
          <CloseIcon />
        </IconButton>
        {ordersList.length > 0 && (
          <React.Fragment>
            <S.TitleSection>{t("orders")}</S.TitleSection>
            {ordersList.map((ord, i) => (
              <React.Fragment key={i}>
                <S.ProductsListContainer>
                  <S.ProductContainer key={i}>
                    <S.ProductTitle>{ord.name}</S.ProductTitle>
                    <div></div>
                    <S.ProductQtdText>
                      ${ord.price.toFixed(2).replace(".", ",")} x {ord.qtd}
                    </S.ProductQtdText>
                    <S.ProductPrice>
                      $
                      {(ord.price * (ord.qtd || 0))
                        .toFixed(2)
                        .replace(".", ",")}
                    </S.ProductPrice>
                  </S.ProductContainer>
                </S.ProductsListContainer>
              </React.Fragment>
            ))}

            <Divider
              sx={{
                my: "12px",
              }}
            />
          </React.Fragment>
        )}
        <S.TotalToPayTitle>
          Total: <span>${ordersValue.toFixed(2).replace(".", ",")}</span>
        </S.TotalToPayTitle>
        <S.ButtonsContainer>
          <Button
            onClick={pay}
            disabled={ordersValue <= 0}
            style={{
              background: ordersValue <= 0 ? "#494949" : "#000",
              color: ordersValue <= 0 ? "#fffa" : "#fff",
              marginTop: 12,
              gap: 6,
            }}
          >
            {t("payByKovigo")}
          </Button>
          <Button
            onClick={pay}
            disabled={ordersValue <= 0}
            style={{
              background: ordersValue <= 0 ? "#494949" : "#000",
              color: ordersValue <= 0 ? "#fffa" : "#fff",
              marginTop: 12,
              gap: 6,
            }}
          >
            {t("payOnSite")}
          </Button>
        </S.ButtonsContainer>
      </S.DrawerContainer>
    </SwipeableDrawer>
  );
}
