import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

export interface FiltersTypes {
  filters: {
    search: string;
  };
  setFilters: any;
  match: (values: any[], s: string) => any[];
  setCategorySelected: Dispatch<SetStateAction<any>>;
  categorySelected: any;
  setIsFilteringCategory: Dispatch<SetStateAction<boolean>>;
  isFilteringCategory: boolean;
}

const FiltersContext = createContext<FiltersTypes | undefined>(undefined);

export function FiltersProvider({children}: any) {
  const [filters, setFilters] = useState<FiltersTypes["filters"]>({
    search: "",
  });

  const [categorySelected, setCategorySelected] = useState();
  const [isFilteringCategory, setIsFilteringCategory] = useState(false);

  const match: FiltersTypes["match"] = (values: any[], s: string) => {
    const parsed = s
      .toUpperCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    const p: any = Array.from(parsed).reduce(
      (a, v, i) => `${a}[^${parsed.substr(i)}]*?${v}`,
      ""
    );
    const re = RegExp(p);
    return values.filter((v) => v.name.toUpperCase().match(re));
  };

  return (
    <FiltersContext.Provider
      value={{
        filters,
        setFilters,
        match,
        setCategorySelected,
        categorySelected,
        setIsFilteringCategory,
        isFilteringCategory,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
}

export function useFilters() {
  return useContext<FiltersTypes | undefined>(FiltersContext);
}
