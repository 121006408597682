import styled from "styled-components";

export const CategoriesListContainer = styled.div`
  display: grid;
  padding: 12px;
  gap: 8px;
  grid-template-columns: repeat(4, 1fr);
`;

export const CompanyName = styled.p`
  position: absolute;
  bottom: 12px;
  right: 12px;
  font-size: 28px;
  line-height: 28px;
  color: #fff;
  font-weight: 700;
`;
