import {ThemeProvider, createTheme} from "@mui/material/styles";
import {FiltersProvider} from "./contexts/filters";
import {ProductsProvider} from "./contexts/products";
import {SnackbarProvider} from "notistack";
import Routes from "./routes";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#757575",
      },
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            padding: "7px 14px 6px 14px !important",
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            fontWeight: 700,
            background: "#fff",
            height: 36,
            padding: 0,
            borderRadius: 6,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            background: "#000",
            color: "#fff",
            fontFamily: "Poppins",
            ":hover": {
              background: "#000",
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <FiltersProvider>
          <ProductsProvider>
            <Routes />
          </ProductsProvider>
        </FiltersProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
