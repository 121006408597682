import React, {useEffect, useState} from "react";
import * as S from "./styles";
import {
  Button,
  CircularProgress,
  IconButton,
  SwipeableDrawer,
} from "@mui/material";
import {useProducts} from "../../contexts/products";
import CheckIcon from "@mui/icons-material/Check";
import {collection, addDoc, updateDoc, doc, getDoc} from "firebase/firestore";
import {db} from "../../firebase";
import {ICreateOrder, IOrder} from "../../models/order";
import {v4 as uuid} from "uuid";
import CloseIcon from "@mui/icons-material/Close";
import {t} from "i18next";
import {companyDoc} from "../../repositories/companyRepositories";

export function DrawerOrder({open, onClose, onOpen}: any) {
  const {
    productsSelected,
    totalValueProductsSelected,
    clearProductsSelectedAndQtd,
    tableId,
    companyId,
  }: any = useProducts();
  const [loadingOrderButton, setLoadingOrderButton] = useState(false);

  const disableOrderButton = loadingOrderButton || productsSelected.length <= 0;

  async function confirmOrder() {
    setLoadingOrderButton(true);

    let deviceId: any = localStorage.getItem("deviceId");

    if (!deviceId) {
      deviceId = uuid();
      localStorage.setItem("deviceId", deviceId);
    }

    const order: ICreateOrder = {
      id: uuid(),
      orderedAt: new Date(),
      products: productsSelected,
      status: "not-prepared",
      tableId,
      companyId,
      deviceId,
    };

    try {
      const ordId = localStorage.getItem("orderId");
      if (ordId) {
        const orderRef = await doc(companyDoc(), "orders", ordId);
        await getDoc(orderRef).then((docSnap: any) => {
          let currentProducts: any[] = [];
          if (docSnap.exists()) {
            const data = docSnap.data();
            if (data && data.products && Array.isArray(data.products)) {
              currentProducts = data.products;
            }
          }
          currentProducts.push(...productsSelected);
          return updateDoc(orderRef, {products: currentProducts});
        });
      } else {
        const orderAdd = await addDoc(
          collection(companyDoc(), "orders"),
          order
        );
        localStorage.setItem("orderId", orderAdd.id);
      }
      clearProductsSelectedAndQtd();
      onClose();
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => setLoadingOrderButton(false), 200);
    }
  }

  return (
    <SwipeableDrawer
      swipeAreaWidth={0}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        position: "absolute",
        top: 12,
      }}
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
    >
      <S.DrawerContainer>
        <IconButton
          onClick={onClose}
          sx={{
            width: 30,
            height: 30,
            position: "absolute",
            right: 6,
            top: 6,
          }}
        >
          <CloseIcon />
        </IconButton>
        <S.TitleSection>{t("inCart")}</S.TitleSection>
        <S.ProductsListContainer>
          {productsSelected.length <= 0 && (
            <S.ProductQtdText
              style={{
                textAlign: "center",
                width: "100%",
                margin: "12px 0",
              }}
            >
              {t("addSomeProduct")}
            </S.ProductQtdText>
          )}
          {productsSelected.map((p: any, i: number) => (
            <S.ProductContainer key={i}>
              <S.ProductTitle>{p.name}</S.ProductTitle>
              <div></div>
              <S.ProductQtdText>
                ${p.price.toFixed(2).replace(".", ",")} x {p.qtd}
              </S.ProductQtdText>
              <S.ProductPrice>
                ${(p.price * p.qtd).toFixed(2).replace(".", ",")}
              </S.ProductPrice>
            </S.ProductContainer>
          ))}
          <S.TotalToPayInOrderCOntainer>
            <S.TotalToPayInOrderText>
              {t("orderInCartPrice")}: $
              {totalValueProductsSelected.toFixed(2).replace(".", ",")}
            </S.TotalToPayInOrderText>
          </S.TotalToPayInOrderCOntainer>
        </S.ProductsListContainer>
        <Button
          disabled={disableOrderButton}
          onClick={confirmOrder}
          style={{
            background: disableOrderButton ? "#494949" : "#000",
            color: disableOrderButton ? "#fffa" : "#fff",
            gap: 6,
          }}
        >
          {loadingOrderButton ? (
            <CircularProgress size={24.5} />
          ) : (
            <React.Fragment>
              <CheckIcon />
              {t("confirmOrder")}
            </React.Fragment>
          )}
        </Button>
      </S.DrawerContainer>
    </SwipeableDrawer>
  );
}
