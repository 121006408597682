import styled from "styled-components";

export const ProductsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 0 12px;
  padding-bottom: 62px;
`;

export const ProductContainer = styled.div`
  display: grid;
  box-shadow: 0 2px 4px #0003;
  border-radius: 6px;
  grid-template-columns: 1fr 100px;
  grid-template-rows: 20px 1fr;
  row-gap: 8px;
  column-gap: 8px;
  padding: 12px;
`;

export const ProductTitle = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
`;

export const ProductDescription = styled.p`
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;
  color: #757575;
  align-self: self-end;
`;

export const ProductPrice = styled.p`
  font-size: 20px;
  text-align: end;
  font-weight: 600;
  color: #3a3;
  line-height: 20px;
`;

export const ProductQtdContainer = styled.div`
  display: flex;
  gap: 2px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-self: end;
  align-items: center;
`;

export const ProductQtdText = styled.p`
  font-size: 15px;
  font-weight: 500;
  color: #757575;
  line-height: 15px;
`;
