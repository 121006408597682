import React, {useEffect, useState} from "react";
import * as S from "./styles";
import {IconButton} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {collection, getDocs, getDoc, query, where} from "firebase/firestore";
import {db} from "../../firebase";
import {useFilters} from "../../contexts/filters";
import {useProducts} from "../../contexts/products";
import {t} from "i18next";
import {companyDoc} from "../../repositories/companyRepositories";

export function ProductsList() {
  const {
    filters,
    match,
    categorySelected,
    setIsFilteringCategory,
    isFilteringCategory,
  }: any = useFilters();
  const {
    products,
    setProducts,
    removeProduct,
    addProduct,
    selectedProducts,
    companyId,
  }: any = useProducts();

  useEffect(() => {
    (async () => {
      setIsFilteringCategory(true);
      let arr: any = [];

      const productsCollection = collection(companyDoc(), "products");
      const productsData = await getDocs(
        query(
          productsCollection,
          categorySelected?.id
            ? where("categoryId", "==", categorySelected?.id)
            : where("highlight", "==", true)
        )
      );

      await productsData.forEach(async (doc) => {
        arr.push({
          id: doc.id,
          ...doc.data(),
          qtd: 0,
        });
      });
      setProducts(
        arr.map((i: any) => {
          const selectedEqualThis = selectedProducts.find(
            (it: any) => it.id === i.id
          );
          return selectedEqualThis?.id === i.id
            ? {...i, qtd: selectedEqualThis.qtd}
            : i;
        })
      );
      setIsFilteringCategory(false);
    })();
  }, [categorySelected]);

  const listProducts = match(products, filters.search);

  return (
    <S.ProductsListContainer>
      <S.ProductTitle>
        {categorySelected?.name || t("highlights")}
      </S.ProductTitle>

      {listProducts.length <= 0 && (
        <S.ProductDescription
          style={{
            marginTop: 12,
            fontSize: 18,
            fontWeight: 400,
            textAlign: "center",
            alignSelf: "center",
          }}
        >
          {t("noneProductFound")}
        </S.ProductDescription>
      )}
      {listProducts.map((p: any, i: number) => (
        <S.ProductContainer key={i}>
          <S.ProductTitle>{p.name}</S.ProductTitle>
          <S.ProductPrice>
            ${p.price.toFixed(2).replace(".", ",")}
          </S.ProductPrice>
          <S.ProductDescription>{p.description}</S.ProductDescription>
          <S.ProductQtdContainer>
            <IconButton onClick={() => removeProduct(p)}>
              <RemoveIcon />
            </IconButton>
            <S.ProductQtdText>{p.qtd || 0}</S.ProductQtdText>
            <IconButton onClick={() => addProduct(p)}>
              <AddIcon />
            </IconButton>
          </S.ProductQtdContainer>
        </S.ProductContainer>
      ))}
    </S.ProductsListContainer>
  );
}
