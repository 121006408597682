import styled from "styled-components";

export const BottomContainer = styled.div`
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background-color: #fff;
  border-top: 1px solid #757575;
  position: fixed;
  z-index: 1;
  gap: 12px;
  button {
    width: 100% !important;
  }
`;
