import React from "react";
import * as S from "./styles";
import {
  Button,
  Drawer,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CheckIcon from "@mui/icons-material/Check";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {useFilters} from "../../contexts/filters";
import {DrawerOrder} from "../DrawerOrder";
import {t} from "i18next";

export function Header() {
  const {setFilters, filters}: any = useFilters();

  return (
    <S.HeaderContainer>
      <IconButton>
        <MenuIcon
          style={{
            color: "#000",
          }}
        />
      </IconButton>
      <TextField
        variant="outlined"
        style={{
          width: 180,
        }}
        onChange={(e) => {
          setFilters({
            ...filters,
            search: e.target.value,
          });
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        placeholder={t("search")}
      />
      <IconButton>
        <NotificationsIcon
          style={{
            color: "#000",
          }}
        />
      </IconButton>
    </S.HeaderContainer>
  );
}
