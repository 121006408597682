import React, {useEffect, useState} from "react";
import * as S from "./styles";
import {Button, CircularProgress} from "@mui/material";
import {collection, getDocs, getDoc} from "firebase/firestore";
import {db} from "../../firebase";
import {useFilters, FiltersTypes} from "../../contexts/filters";
import {companyDoc} from "../../repositories/companyRepositories";

interface Category {
  name: string;
  id: string;
  order: number;
}

export function CategoriesList() {
  const [categories, setCategories] = useState<Category[]>([]);
  const {categorySelected, setCategorySelected, isFilteringCategory}: any =
    useFilters();

  useEffect(() => {
    (async () => {
      const categoriesCollection = collection(
        companyDoc(),
        "productCategories"
      );
      const categoriesData = await getDocs(categoriesCollection);

      const arr: any = [];
      await categoriesData.forEach(async (doc) => {
        arr.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setCategories(arr);
    })();
  }, []);

  return (
    <S.CategoriesListContainer>
      {categories
        .sort((a, b) => a.order - b.order)
        .map((category, i) => {
          const selected = categorySelected?.id === category?.id;
          return (
            <Button
              sx={{
                background: selected ? "#4ae !important" : "#000 !important",
              }}
              key={i}
              onClick={() =>
                setCategorySelected(selected ? undefined : category)
              }
            >
              {isFilteringCategory && selected ? (
                <CircularProgress size={24.5} />
              ) : (
                category.name
              )}
            </Button>
          );
        })}
    </S.CategoriesListContainer>
  );
}
