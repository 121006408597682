import React from "react";
import * as S from "./styles";
import {Button} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import PaymentsIcon from "@mui/icons-material/Payments";
import {DrawerOrder} from "../DrawerOrder";
import {t} from "i18next";
import {DrawerPay} from "../DrawerPay";

export function BottomTab() {
  const [drawerOrderOpen, setDrawerOrderOpen] = React.useState(false);
  const [drawerPayOpen, setDrawerPayOpen] = React.useState(false);

  return (
    <React.Fragment>
      <S.BottomContainer>
        <Button
          sx={{
            gap: 1,
          }}
          onClick={() => setDrawerOrderOpen(true)}
          variant="contained"
        >
          <CheckIcon />
          {t("order")}
        </Button>
        <Button
          sx={{
            gap: 1,
          }}
          onClick={() => setDrawerPayOpen(true)}
          variant="contained"
        >
          <PaymentsIcon />
          {t("pay")}
        </Button>
      </S.BottomContainer>
      <DrawerOrder
        open={drawerOrderOpen}
        onClose={() => setDrawerOrderOpen(false)}
        onOpen={() => setDrawerOrderOpen(true)}
      />
      <DrawerPay
        open={drawerPayOpen}
        onClose={() => setDrawerPayOpen(false)}
        onOpen={() => setDrawerPayOpen(true)}
      />
    </React.Fragment>
  );
}
